import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

export const SellerNav = () => {
  const navigate = useNavigate();

  const handleSignOut = () => {
    // Remove the sessionStorage items
    sessionStorage.removeItem('Vehicle_Id');
    sessionStorage.removeItem('user_id');
    sessionStorage.removeItem('book_vehicle_id');
    sessionStorage.removeItem('vehicleIds');
    
    // Perform sign-out actions here (if any)
    // For now, just navigate to the sign-in page
    navigate("/sellerin");
  };

  return (
    <section className="car-details">
      <header className="py-3">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light p-0">
            <NavLink className="navbar-brand" to="https://carchaser.ca/">
              <img src="../images/logo.png" alt="logo-img" />
            </NavLink>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink className="nav-link" to="https://carchaser.ca/" activeClassName="active">Sell My Car <span className="sr-only">(current)</span></NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/view-posts" activeClassName="active">View Posts</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/booked-inspection-appointments" activeClassName="active">Booked Inspection Appointments</NavLink>
                </li>
              </ul>
            </div>
            <div className="signout-btn">
              <button className="btn btn-primary" onClick={handleSignOut}>Sign Out</button>
            </div>
          </nav>
        </div>
      </header>
    </section>
  );
};
